<template>
<div class="my-4">
  <b-container>
    <b-row class="mb-4" v-if="!loadingNews">
      <b-col>
        <b-card class="mb-3">
          <b-row>
            <b-col>
              <b-button class="mr-2" variant="outline-primary" :to="{ name: 'Articles' }">insights</b-button>
              <span class="mb-4" v-if="permissions.edit">
                <b-button class="mr-2" variant="outline-primary" :to="{ name: 'WorkflowArticle', params: {id: article.id } }">edit</b-button>
                workflow stage: <strong>{{workflow.find(x => x.id === article.stage).name}}</strong>
              </span>
            </b-col>
            <b-col class="text-right">
              <b-button class="mr-2 text-right" variant="outline-primary" @click="$router.go(-1)">back</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h1 class="ml-0 my-4">{{article.title}}</h1>
              <div class="mb-2">
                <strong v-if="authors.length > 1">Authors: </strong>
                <strong v-else>Author: </strong>
                <span v-for="(author, index) in authors" :key="author">
                  <a :href="'mailto:' + author">{{author}}</a><span v-if="index + 1 < authors.length">, </span>
                </span>
              </div>
              <div class="mb-2">
                <strong>Published: </strong><span>{{ moment(article.approved_at).format('YYYY-MM-DD') }}</span>
              </div>
              <div class="mb-2" v-if="article.events.length > 0">
                <strong>Events: </strong>
                <li class="ml-4" v-for="(event) in article.events" :key="'event' + event.id">
                  <router-link :to="{ name: 'Events', params: { id: event.id}}" ><strong>{{ moment(event.date).format('YYYY-MM-DD') }}</strong> - {{event.name}}</router-link><a v-if="article.events.length > 1"></a>
                </li>
              </div>
              <div class="mb-2" v-if="article.regtopics.length > 0">
                <strong>Topics: </strong>
                <span v-for="(topic, index) in article.regtopics" :key="'topic' + topic.id">
                  <router-link :to="{ name: 'Topic', params: { id: topic.id}}" >{{topic.name}}</router-link><span v-if="index + 1 < article.regtopics.length"> | </span>
                </span>
              </div>
              <div class="mb-2" v-if="article.risks.length > 0">
                <strong>Risks: </strong>
                <span v-for="(risk, index) in article.risks" :key="'risk' + risk.id">
                  <router-link :to="{ name: 'Risk', params: { id: risk.id}}" >{{risk.name}}</router-link><span v-if="index + 1 < article.risks.length"> | </span>
                </span>
              </div>
              <div class="mb-2" v-if="article.tags.length > 0">
                <strong>Tags: </strong>
                <span v-for="(tag, index) in article.tags" :key="'tag' + tag.id">
                  <router-link :to="{ name: 'NewsTag', params: { id: tag.id}}" >{{ tag.name }}</router-link><span v-if="index + 1 < article.tags.length"> | </span>
                </span>
              </div>
              <div class="mb-4" v-if="article.publications.length > 0">
                <strong>News: </strong>
                <span v-for="(publication, index) in article.publications" :key="'publication' + publication.id">
                  <router-link :to="{ name: 'NewsItem', params: { id: publication.id}}" >{{publication.name}}</router-link><span v-if="index + 1 < article.publications.length"> | </span>
                </span>
              </div>
              <div v-html="article.body"></div>
              <b-row>
                <b-col id="regulationContainer" v-if=showPdf class="pdfViewer text-center" align-v="center">
                  <h1 v-if=loadingPdf class=mt-4><i class="fa fa-spinner fa-pulse fa-lg fa-fw text-center" align-v="center"></i>Loading...</h1>
                  <div class="page" v-for="page in pages" :key="'page-'+ page" :id="'page' + page">
                    <div class="canvasWrapper"><canvas :id="'pdfpage'+ page"></canvas></div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="loadingNews" class="text-center mb-4" align-h="center">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import async from 'async'
import moment from 'moment'
import ac from '../libs/accesscontrol'
import workflow from '@/workflows/article'
const pdfjs = require('pdfjs-dist/legacy/build/pdf.js')

// eslint-disable-next-line
const PdfjsWorker = require("worker-loader?esModule=false&filename=[name].js!pdfjs-dist/legacy/build/pdf.worker.js")

if (typeof window !== "undefined" && "Worker" in window) {
  pdfjs.GlobalWorkerOptions.workerPort = new PdfjsWorker()
}

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

let pdf

const render = async function (pageNumber) {
  try {
    const page = await pdf.getPage(pageNumber)
    const viewport = page.getViewport({ scale: 1.7 })
    const canvas = document.getElementById('pdfpage' + pageNumber)
    const context = canvas.getContext('2d')
    canvas.height = viewport.height
    canvas.width = viewport.width
    const renderContex = {
      canvasContext: context,
      viewport: viewport
    }
    await page.render(renderContex)
  } catch (e) {
    console.log(e)
  }
}

export default {
  components: {
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permissions.edit = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.firstLoad()
  },
  data () {
    return {
      formEventValidated: false,
      gridLoaderColor: 'black',
      gridLoaderSize: '10px',
      article: {},
      authors: {},
      loadingNews: true,
      moment: moment,
      permissions: {},
      workflow: workflow,
      showPdf: false,
      ready: false,
      loading: true,
      loadingPdf: true,
      pdfUrl: '',
      pages: [],
      pdfUploaded: false
    }
  },
  methods: {
    firstLoad: async function () {
      this.$logger.debug('first load started')
      try {
        let apiName = 'cosmos'
        let path = `/article/v2/${this.$route.params.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('firstLoad:')
        this.$logger.debug({ response })
        this.article = response.article
        let authors = []
        _.each(response.article_history, x => {
          authors.push(x.created_by)
        })
        this.authors = _.sortedUniq(authors)
        if (!this.article.approved_by) {
          if (!this.permissions.edit) {
            this.article.title = "Permission not granted"
            this.article.body = "<p>You do not have permissions to see this article</p>"
          }
        }
        if (this.article.pdf_uploaded) {
          this.loadPdf()
        }
        this.loadingNews = false
        this.$stat.log({ page: 'article', action: 'open article', model: 'article', model_id: this.$route.params.id })
      } catch (e) {
        this.$logger.warn('saved ERROR: ' + e)
      }
    },
    loadPdf: async function () {
      this.showPdf = true
      this.loadingPdf = true
      try {
        this.uploadUrl = {}
        let apiName = 'cosmos'
        let path = '/getObject/insights/pdf/' + this.article.id + '.pdf'
        this.pdfUrl = await this.$Amplify.API.get(apiName, path)
        // render PDF
        const pdfTask = pdfjs.getDocument(this.pdfUrl.URL)
        pdf = await pdfTask.promise
        const pages = pdf.numPages
        for (let i = 1; i <= pages; i++) {
          this.pages.push(i)
        }
        this.ready = true
        await delay(100)
        await async.eachSeries(this.pages, async.asyncify(render))
        this.$emit('render-completed')
        this.loadingPdf = false
      } catch (e) {
        console.log(e)
      }
    },
    onRenderCompleted: function () {
      this.annotationsHighlight()
      this.ready = true
      this.loading = false
    }
  }
}
</script>

<style>
.pdfViewer {
  padding-bottom: 5px;
}

.pdfViewer .canvasWrapper {
  overflow: hidden;
}

.pdfViewer .page {
  direction: ltr;
  margin: 10px;
  position: relative;
  overflow: visible;
  border: 10px solid;
  background-clip: content-box;
  border-image: url('/img/shadow.png') 9 9 repeat;
  background-color: rgba(255, 255, 255, 1);
}

.pdfViewer .page canvas {
  margin: 0;
  display: block;
}
</style>
